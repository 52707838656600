header {
  background-color: white;
  padding: $margin;
  position: relative;
}
h1 {
  font-size: 1px;
  height: 50px;
  margin: 0;
  text-align: center;
}
h1 .logo {
  display: inline-block;
  height: 100%;
  margin: 0;
  width: auto;
}

@media only screen and (min-width: 1200px){
  header {
    padding: (1.5 * $margin);
  }
}