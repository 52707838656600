.block-full-width-image {

  margin-left: $margin;
  margin-right: $margin;

  img {
    display: block;
    height: auto;
    width: 100%;
  }

}

@media only screen and (min-width: 900px) {

  .block-full-width-image {
    height: calc(100vh - 50px - (3 * #{$margin}));
    max-height: 40vw;
    overflow: hidden;
    position: relative;
    img { 
      position: absolute;

      top: 50%;
      left: 0;
      transform: translate(0, -50%);

    }
  }
}

@media only screen and (min-width: 1200px) {
  .block-full-width-image {
    height: calc(100vh - 50px - (4 * #{$margin}));
    margin-left: (1.5 * $margin);
    margin-right: (1.5 * $margin);
  }
}