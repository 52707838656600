.block-carousel,
.block-carousel-with-thumbnails {
  position: relative;
  padding: 0;
}
.carousel-wrapper {
  background-color: white;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
  padding: $margin;
  height: calc(100vh - 50px - #{$margin});
  position: relative;
  width: 100%;
  li {
    list-style: none;
  }
}
.carousel-slides {
// default styling before the slideshow is loaded
  padding: 0;
  margin: 0;
  li {
    display: none;
  }
  li:first-child {
    display: block;
  }
  img {
    width: 100%;
  }    
}
.block-carousel:first-child,
.block-carousel-with-thumbnails:first-child {
  margin-top: -$margin;
}
.rslides,
.rslides_thumbs {
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;

  li {
    display: none;
    backface-visibility: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  li:first-child {
    position: relative;
    display: block;
    float: left;
  }
}

.rslides {

  // center align images
  img {
    display: block;
    position: absolute;

    right: 0;
    bottom: 0;

    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }  

}

.rslides_thumbs {
  // contain images
  img {
    position: static;
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}

.rslides_thumbs_nav {
  display: block;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 9;
  overflow: hidden;
  text-align: -9999px;

  &.next {
    right: 0;
  }
}

.rslides_tabs {
  bottom: 0.5em;
  left: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;
  li {
    display: inline-block;
    height: 1em;
    list-style: none outside none;
    margin: 0 0.25em;
    overflow: hidden;
    width: 1em;
  }
  a {
//    background-color: red;
    display: block;
    height: 100%;
    position: relative;
    text-indent: -9999px;
    width: 100%;
  }
  a:after {
    content: " ";
    background-color: white;
    border-radius: 0.25em;
    display: block;
    height: 0.5em;
    left: 0.25em;
    position: absolute;
    top: 0.25em;
    width: 0.5em;
  }
  .rslides_here a:after {
    background-color: #808080;
  }
}

.carousel-counter {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 2;
  text-align: center;
  color: #fff;
}

.carousel-thumbnails {
  display: none;
  margin-top: 20px;

  ul { padding: 0; }
  li { list-style: none outside none; }
}


@include mq($medium) {

  .block-carousel:first-child {
    margin-top: -(1.5 * $margin);
  }
  .carousel-wrapper {
    height: calc(100vh - 50px - (1.5 * #{$margin}));
    padding: (1.5 * $margin);
  }

  .carousel-with-thumbnails-wrapper {
    height: calc(100vh - 50px - (1.5 * #{$margin}) - 120px);
    padding: (1.5 * $margin) (1.5 * $margin) 0;
  }

  .carousel-counter {
    display: block;
    top: calc(100vh - (6 * #{$margin}) - 120px);
  }

  .carousel-thumbnails {
    display: block;
    padding: 0 (1.5 * $margin);
    ul {
      display: flex;
      margin: 0 0 0 -20px;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: 0;
    }
    li {
      display: inline-block;
      margin: 0 0 20px 20px;
      height: 100px;
    }
    li:last-child {
      margin-right: auto;
    }
    a,
    img {
      display: block;
      width: auto !important;
      height: 100% !important;
    }
    .rslides_thumbs_here {
      opacity: 0.5;
      a {
        cursor: default;
      }
    }
  }

}
