.block-text-image {

  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $margin;
  padding-right: $margin;

  img {
    display: block;
    height: auto;
    width: 100%;    
  }

}

@media only screen and (min-width: 900px) {

  .block-text-image {

    @include clearfix();

    img {
      float: right;
      height: auto;
      margin-left: 3 * $margin;
      width: 40%;
    }
  }

}

@media only screen and (min-width: 1200px) {

  .block-text-image {
    padding-left: (1.5 * $margin);
    padding-right: (1.5 * $margin);

    img {
      margin-left: 4 * $margin;
      width: 30%;
    }
  }

}