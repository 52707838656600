nav {
  li {
    list-style: none outside none;
  }
  a {
    text-decoration: none;
  }
}
.main-navigation {
  box-sizing: border-box;
  width: 100%;
  li,
  p {
    font-size: 0.8em;
    letter-spacing: 2px;
    line-height: calc(0.8em - 0.5vw);
    text-transform: uppercase;    
  }
  ul {
    display: none;
    padding: 0;
  }
  li {
    float: left;
    margin: 0 1.8vw 0 0;
  }
  .contact {
    margin: $margin 0 0;
    text-align: center;
  }
}

// hide the home-link on the homepage
.front .main-menu .active {
  display: none;
}

@media only screen and (min-width: 900px) {
  .main-navigation {
    height: 40px;
    left: 0;
    padding: $margin;
    top: 0;
    position: absolute;
    ul {
      display: block;
    }
    .contact {
      display: inline-block;
      float: right;
      margin: 0;
      text-align: right;
    }
  }  
}

@media only screen and (min-width: 1200px) {
  .main-navigation {
    padding: 1.5 * $margin;
  }
}