/* hide the menu while it's in the page */
.combined-page {
  .submenu { display: none; }
}

*[class^="block-"] {
  padding: ($margin * 2) 0;
}
// collapse padding
*[class^="block-"] + *[class^="block-"] {
  margin-top: -$margin;
}
// but not after...
.block-full-width-image + *[class^="block-"],
// ...or before a full-width image itself
* + .block-full-width-image
 {
  margin-top: 0 !important;
}

@media only screen and (min-width: 1200px) {

  *[class^="block-"] {
    padding: ($margin * 3) 0;
  }
  *[class^="block-"] + *[class^="block-"] {
    margin-top: -($margin * 2);
  }
  // but smaller margin after...
  .block-full-width-image + *[class^="block-"],
  // ...or before a full-width image itself
  * + .block-full-width-image {
    margin-top: -$margin !important;
  }

}
