// dev only
/*
body {
  background-color: blue;
}
*/

$margin: 20px;

$small:  500px;
$medium: 900px;
$large: 1200px;
