@font-face {
  font-family: "Fakt Semicondensed";
  src: url("../fonts/FaktSmConPro-Normal.eot");
  src: url("../fonts/FaktSmConPro-Normal.eot?#iefix") format("embedded-opentype"),
       url("../fonts/FaktSmConPro-Normal.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Fakt Semicondensed";
  src: url("../fonts/FaktSmConPro-SemiBold.eot");
  src: url("../fonts/FaktSmConPro-SemiBold.eot?#iefix") format("embedded-opentype"),
       url("../fonts/FaktSmConPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: "Fakt Semicondensed", "Franklin Gothic", Arial, sans-serif;
  font-size: calc(12px + 2vw);
  line-height: 1.5;
}

@media only screen and (min-width: 500px) {
  body {
    font-size: calc(12px + 1vw); 
  }
}

@media only screen and (min-width: 900px) {
  body {
    font-size: calc(12px + 0.5vw);
  }
}