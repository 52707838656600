footer.section {
  font-size: 0.8em;
  text-align: center;
  a {
    text-decoration: none;
  }
}

@media only screen and (min-width: 900px) {
  footer.section {
    font-size: 1em;
  }
}