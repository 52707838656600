.hidden {
  position: absolute;
  left: -9999px;
}
.reader-only { 
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}
.clear { 
  clear: both; 
}