.shortcuts-bar {
  background: #f35440;
  border-top: 1px solid #d62d1d;
  border-bottom: 1px solid #d62d1d;
  font-family: Open Sans, "Segoe UI", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4em;
  .menu { 
    display: inline-block;
    margin: 0;
    padding: 0;
    @include clearfix();
    li {
      background-image: none;
      float: left;
      height: 100%;
      list-style-image: none;
      list-style-type: none;
      margin: 0 !important;
      padding: 0;
    }
    a {
      background: transparent none;
      border: none;
      color: white;
      display: block;
      font-weight: normal;
      padding: 4px 8px;
      text-align: left;
      text-decoration: none;
    }
    a:hover {
      background: #d62d1d;
      color: white;
    }
    .active,
    .active:hover {
      background: transparent;
      color: #d62d1d;
      cursor: default;
    }
  }

}

.page-user-login main.section {
  max-width: 500px;
  margin: (2 * $margin) auto;
  padding: 0 ($margin);
}
.page-user-login {
  input[type="text"],
  input[type="password"] {
    border: 0;
    outline: 1px solid black;
    box-sizing: border-box;
    padding: 0.375em 0.375em 0.25em;
    font-size: 1.25em;
    line-height: 1;
    margin: 2px 0 0.5em;
    width: 100%;
    &:focus {
//      outline: 3px solid black;
      background: black;
      color: white;
    }
  }
  input[type="submit"] {
    background: white;
    border: 0;
    outline: 1px solid black;
    display: inline-block;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.75em 0.75em 0.5em;
    &:focus {
      background: black;
      color: white;
    }
  }
  .form-actions {
    text-align: center;
  }
}