@mixin clearfix(){
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin mq($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}